import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { gender, listingStores } from '../../../config/marketplace-custom-config';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import { IconCollection } from '../../../components';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    history,
    urlQueryParams,
    shortBy,
  } = props;

  const classes = classNames(rootClassName || css.root, className, !shortBy && css.topHeader);

  const CategorySelected = history.location.search.split('?pub_gender=')[1];

  return (
    <div className={classes}>
      {!shortBy ? (
        <div className={css.searchOptions}>
          <div className={css.searchResultSummary}>
            <div className={css.categoryWrapper}>
              {listingStores.map(st => {
                const search = { ...urlQueryParams, pub_listingStore: `${st.key}` };
                return (
                  <div
                    key={st.key}
                    onClick={() => {
                      if(st.key === urlQueryParams?.pub_listingStore){
                        const routes = routeConfiguration();
                        history.push(createResourceLocatorString('SearchPage', routes, {}, {...urlQueryParams,pub_listingStore: null}));
                      }else{
                        const routes = routeConfiguration();
                        history.push(createResourceLocatorString('SearchPage', routes, {}, search));
                      }
                    }}
                    className={css.categoryItem}
                  >
                    <div
                      className={classNames(
                        css.iconWrapper,
                        urlQueryParams.pub_listingStore == st.key && css.activeStore
                      )}
                    >
                      <span className={css.icon}>
                        {urlQueryParams.pub_listingStore == st.key ? (
                          <IconCollection icon={st.key} />
                        ) : (
                          <img src={st.icon} alt="icon" />
                        )}
                      </span>
                      <span> {st.label}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={css.tabs}>
            <div
              className={classNames(css.tabItems,!(urlQueryParams?.pub_gender) && css.activeTabItems)}
              onClick={() => {
                const routes = routeConfiguration();
                history.push(createResourceLocatorString('SearchPage', routes, {}, {...urlQueryParams,pub_gender:null}));
              }}
            >
              <FormattedMessage id="MainPanelHeader.all" />
            </div>
            {gender.map(st => {
              const search = { ...urlQueryParams, pub_gender: `${st.key}` };

              return (
                <div
                  key={st.key}
                  onClick={() => {
                    const routes = routeConfiguration();
                    history.push(createResourceLocatorString('SearchPage', routes, {}, search));
                  }}
                  className={classNames(css.tabItems,urlQueryParams?.pub_gender === st.key && css.activeTabItems)}
                >
                  {st.label}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={css.searchResult}>
          <div className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </div>
          <div>
            {/* <span className={css.sortyBy}>
            <FormattedMessage id="MainPanelHeader.sortBy" />
          </span> */}
            {sortByComponent}
          </div>
        </div>
      )}

      {children}

      {noResultsInfo ? !shortBy && noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
